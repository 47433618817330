import React from 'react';
import {
  AppStore
} from '../../stores';

const routeParams = window.location.pathname.split('/');

const screenId = routeParams[1];
let isPreview = false;

if (routeParams.map(param => param.toLowerCase()).includes('preview')) {
  isPreview = true;
}

export default React.createContext({
  appStore: new AppStore(
    screenId !== undefined ? screenId : '',
    isPreview
  ),
});
