class WidgetProvider {
    /**
     * @var {[name: string]: {name: string, props: {}, component: ReactElement}}
     */
    widgets = {}

    /**
     * @param {{name: string, props: {}}} options 
     * @param {ReactElement} component 
     */
    registerWidget(options, component) {
        // console.log(`\%cWidget ${options.name} added!`, 'color: red');
        this.widgets[options.name] = {
            name: options.name,
            props: options.props,
            component
        };
    }

    /**
     * 
     * @param {string} type 
     * @return {[name: string]: {name: string, props: {}, component: ReactElement}}
     */
    getWidget(type) {
        if (typeof this.widgets[type] === undefined) {
            throw new Error(`The widget ${this.name} does not have a widget Component registered!`);
        }

        return this.widgets[type];
    }
}

export default new WidgetProvider();