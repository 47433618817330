import React from 'react';
import { Row } from 'antd';

import styles from './TextWidgetSubView.module.less';
import templates from './TextWidgetTemplates.module.less';
import WidgetViewProvider from '../../../WidgetViewProvider';

const TextWidgetSubView = ({
  text,
  template
}) => (
    <Row className={`${styles.root} ${templates[`${template.background}-bg`]} ${templates[`${template.text}-text`]}`}>
      <div className={styles.container}>
        <Row align="middle" justify="center" style={{ height: "100%"}}>
          <span className={styles.headLine}>
            {text}
          </span>
        </Row>
      </div>
    </Row>
  );

WidgetViewProvider.registerWidgetView({
  widget: 'text',
  orientations: ['portrait'],
  zoneTypes: ['monitor-content--widget'],
}, TextWidgetSubView);
