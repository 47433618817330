import { memo } from "react";

class WidgetViewProvider {
    /**
     * @var {[widget: string]: {component: ReactElement}[]}
     */
    views = {}

    /**
     * @param {{name: string, props: {}}} options 
     * @param {ReactElement} component 
     */
    registerWidgetView(options, component) {
        // console.log(`\%cWidget ${options.name} added!`, 'color: red');
        const { widget, ...other } = options;

        if (!this.views[widget]) {
            this.views[widget] = [];
        }
        this.views[widget].push({
            view: memo(component),
            ...other
        });
    }

    /**
     * @param {string} widget 
     * @return {[widget: string]: {component: ReactElement}}
     */
    getWidgetViews(widget) {
        return this.views[widget];
    }

    /**
     * @param {string} widgetName 
     * @param {Zone} zone 
     */
    getWidgetViewForZone(widgetName, zone) {
        const views = this.getWidgetViews(widgetName);

        const filters = {
            layouts: layouts => layouts.includes(zone.layout.name),
            zoneTypes: zoneTypes => zoneTypes.includes(zone.type),
            orientations: orientations => orientations.includes(zone.layout.orientation),
            props: props => {
                let matches = true;
                Object.keys(props).forEach(prop => {
                    if (typeof zone.currentlyPlayedWidget.props[prop] === undefined || zone.currentlyPlayedWidget.props[prop] !== props[prop]) {
                        matches = false;
                    }
                });
                return matches;
            }
        }

        const filterMaps = [
            ['layouts', 'zoneTypes', 'orientations', 'props'],

            ['layouts', 'zoneTypes', 'orientations'],
            ['layouts', 'zoneTypes', 'props'],
            ['layouts', 'orientations', 'props'],
            ['zoneTypes', 'orientations', 'props'],

            ['zoneTypes', 'orientations'],
            ['layouts', 'zoneTypes'],
            ['layouts', 'orientations'],
            ['layouts', 'props'],

            ['zoneTypes', 'props'],

            ['orientations', 'props'],

            ['props'],
            ['zoneTypes'],
            ['layouts'],
            ['orientations'],
        ];

        for (const filterMap of filterMaps) {
            const filterMapFilters = filterMap.reduce((comm, curr) => {
                comm[curr] = filters[curr];
                return comm;
            }, {});
            const filteredView = this.findWidgetView(views, filterMapFilters);
            if (filteredView) {
                return filteredView.view;
            }
        }

        const defaultWidgetView = this.findWidgetView(views, {
            'default': (def) => def === true
        });

        if (defaultWidgetView) {
            return defaultWidgetView.view;
        }

        return null;
    }

    findWidgetView(views, filters) {
        return views.find(view => {
            let withAllFilters = true;
            
            if ((Object.keys(view).length - 1) !== Object.keys(filters).length) {
                return false;
            }

            Object.keys(filters).forEach(filterByProperty => {
                if (
                    typeof view[filterByProperty] === undefined
                    ||
                    !view[filterByProperty]
                    ||
                    !filters[filterByProperty](view[filterByProperty])
                ) {
                    withAllFilters = false;
                }
            });

            return withAllFilters;
        });
    }
}

export default new WidgetViewProvider();