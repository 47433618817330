import React from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube";

import styles from "./YoutubeWidgetView.module.less";
import WidgetViewProvider from "../../../WidgetViewProvider";

const YoutubeWidgetView = ({ videoId, ...props }) => (
  <div className={styles.container}>
    <YouTube
      style={{ height: "100%" }}
      videoId={videoId}
      opts={{
        height: "100%",
        width: "100%",
        playerVars: {
          autoplay: 1,
          mute: 1,
          controls: 0,
          loop: 1,
          playlist: `${videoId}`,
          modestbranding: 0,
          rel: 0,
        },
      }}
    />
  </div>
);

YoutubeWidgetView.propTypes = {
  videoId: PropTypes.string.isRequired,
};

WidgetViewProvider.registerWidgetView(
  {
    widget: "youtube",
    default: true,
  },
  YoutubeWidgetView
);
