import React, { Component, createElement } from 'react';
import { observer } from 'mobx-react';

import styles from './Loop.module.less';
import {
  LoopContext,
  Loader
} from '../..';
import { Result } from 'antd';
import { LayoutProvider } from '../../layouts';

import {withErrorBoundary} from 'react-error-boundary'
 
function ErrorFallback() {
  return (
    <div className={styles.root}>
      <Result
        status={500}
        title={"Az oldal működése során váratlan hiba lépett fel!"}
        className={styles.result}
      />
    </div>
  )
}

@observer
class Loop extends Component {
  static contextType = LoopContext;

  componentDidMount() {
    this.context.appStore.init();
  }

  render() {
    if (this.context.appStore.loading) {
      return <div className={styles.root}>
        <Loader />
      </div>;
    }

    const layoutComponent = this.context.appStore.settings ? LayoutProvider.getLayout(this.context.appStore.settings.layout.name) : undefined;

    if (!layoutComponent) {
      this.context.appStore.error = {
        status: 500,
        message: 'Screen layout nem található!'
      };
    }

    if (this.context.appStore.error) {
      return <div className={styles.root}>
        <Result
          status={this.context.appStore.error.status}
          title={this.context.appStore.error.message}
          className={styles.result}
        />
      </div>;
    }

    return (
      <div className={styles.root} style={{backgroundImage: `url(${this.context.appStore.settings.background})`}}>
        {createElement(layoutComponent)}
      </div>
    );
  }
}

export default withErrorBoundary(Loop, {
  FallbackComponent: ErrorFallback,
  onError(error, info) {
    console.error(error, info);
    // Do something with the error
    // E.g. log to an error logging client here
    return;
  },
});

