import React from "react";
import PropTypes from "prop-types";
import { Row } from "antd";

import styles from "./ClockWidgetFullScreenPortraitView.module.less";
import WidgetViewProvider from "../../../WidgetViewProvider";

const ClockWidgetFullScreenPortraitView = ({ time, ...props }) => (
  <div className={styles.root} {...props}>
    <Row className={styles.dateRow}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Row>
            <span className={styles.date}>{time.toFormat("LLLL dd")}.</span>
          </Row>
          <Row>
            <span className={styles.day}>{time.toFormat("cccc")}</span>
          </Row>
        </div>
      </div>
    </Row>
    <Row className={styles.timeRow}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Row justify="center">
            <span className={styles.time}>{time.toFormat("HH:mm")}</span>
          </Row>
        </div>
      </div>
    </Row>
  </div>
);

ClockWidgetFullScreenPortraitView.propTypes = {
  time: PropTypes.shape({}).isRequired,
};

WidgetViewProvider.registerWidgetView(
  {
    widget: "time",
    orientations: ["portrait"],
    zoneTypes: ["monitor-content--fullscreen"],
  },
  ClockWidgetFullScreenPortraitView
);
