import { WidgetProps } from "../../Widget/index";
import { action } from "mobx";

const { observable } = require("mobx");

class IframeWidgetProps extends WidgetProps{
    /**
     * @var {string}
     */
    @observable url;

    /**
     * @retrun {object}
     */
    toJson() {
        return {
            url: this.url
        };
    }

    /**
     * @param {object} json
     * @retrun {this}
     */
    @action fromJson(props) {
        this.url = props.url;

        return this;
    }
}

export default IframeWidgetProps;