import React from 'react';
import { Col, Divider, Row } from 'antd';
import Zone from '../../components/Zone/Zone';
import LayoutProvider from '../LayoutProvider';

const portraitSubBottom = () => (
    <>
        <Row style={{height: '31.7vh'}}>
            <Col flex="100vw" style={{height: '31.7vh'}}>
                <Zone zone={0} />
            </Col>
        </Row>
        <Divider style={{ margin: 0, borderTop: '1px solid rgba(255, 255, 255, 0.2)', backgroundColor: '#2F3336' }} />
        <Row style={{height: '31.7vh'}}>
            <Col flex="100vw" style={{height: '31.7vh'}}>
                <Zone zone={1} />
            </Col>
        </Row>
        <Divider style={{ margin: 0, borderTop: '1px solid rgba(255, 255, 255, 0.2)', backgroundColor: '#2F3336' }} />
        <Row style={{height: '31.7vh'}}>
            <Col flex="100vw" style={{height: '31.7vh'}}>
                <Zone zone={2} />
            </Col>
        </Row>
        <Divider style={{ margin: 0, borderTop: '1px solid rgba(255, 255, 255, 0.2)', backgroundColor: '#2F3336' }} />
        <Row style={{height: '4.9vh'}}>
            <Col flex="100vw" style={{height: '4.9vh'}}>
                <Zone zone={3} fullscreenToggler />
            </Col>
        </Row>
    </>
);

LayoutProvider.registerLayout("portrait-1", portraitSubBottom);