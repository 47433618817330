import React from 'react';
import { Row, Col } from 'antd';

import styles from './TextWidgetBottomView.module.less';
import templates from './TextWidgetTemplates.module.less';
import WidgetViewProvider from '../../../WidgetViewProvider';

const TextWidgetBottomView = ({
  headline,
  template,
  text
}) => (
<Row className={`${styles.root} ${templates[`x-${template.background}-bg`]} ${templates[`${template.text}-text`]}`}>
  <div className={styles.container}>
      <Row style={{ height: '100%' }} align="middle" gutter={[16,0]}>
        <Col span={10}>
          <div>
            <Row style={{width: '38vw'}}>
              <span className={styles.headLine}>
                {headline}
              </span>
            </Row>
          </div>
        </Col>
        <Col span={10}>
          <div>
            <Row justify="center">
              <span className={styles.text}>
                {text}
              </span>
            </Row>
          </div>
        </Col>
        <Col span={4}>
          <div>
          </div>
        </Col>
      </Row>
    </div>
  </Row>
);

WidgetViewProvider.registerWidgetView({
  widget: 'text',
  orientations: ['landscape'],
  zoneTypes: ['monitor-content--x'],
}, TextWidgetBottomView);