import React, { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ZoneLoader from '../ZoneLoader/ZoneLoader';
import LoopContext from '../../contexts/LoopContext';
import FullScreenToggler from '../FullScreenToggler/FullScreenToggler';
import { WidgetProvider } from '../../widgets';

@observer
class Zone extends Component{
    static contextType = LoopContext;

    static propTypes = {
        zone: PropTypes.number.isRequired,
        fullscreenToggler: PropTypes.bool.isRequired
    }

    static defaultProps = {
        fullscreenToggler: false
    }

    render() {
        const zone = this.context.appStore.settings.layout.zones[this.props.zone];
        return (
            <>
                <ZoneLoader enabled={zone.loading} />
                {this.props.fullscreenToggler && <FullScreenToggler /> }
                {zone.currentlyPlayedWidget !== null && (
                    createElement(
                        WidgetProvider.getWidget(zone.currentlyPlayedWidget.name).component,
                        {
                            zone,
                            ...zone.currentlyPlayedWidget.props
                        }
                    )
                )}
            </>
        );
    }
}

export default Zone;