import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";

import styles from "./WeatherWidgetFullScreenPortraitView.module.less";
import WidgetViewProvider from "../../../WidgetViewProvider";
import WeatherIcon from "../atoms/WeatherIcon";

const WeatherWidgetFullScreenPortraitView = ({
  cityName,
  time,
  dayPart,
  enableClock,
  temperature,
  group,
  description,
  ...props
}) => (
  <div {...props}>
    <Row className={styles.dateAndTimeRow}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Row>
            <span className={styles.date}>{time.toFormat("LLLL dd")}.</span>
          </Row>
          <Row>
            <span className={styles.day}>{time.toFormat("cccc")}</span>
          </Row>
          {enableClock && (
            <Row>
              <span
                className={`${styles.time} ${
                  time.toFormat("HH:mm").indexOf(1) === 0
                    ? styles.timeForOne
                    : null
                } `}
              >
                {time.toFormat("HH:mm")}
              </span>
            </Row>
          )}
        </div>
      </div>
    </Row>
    <Row className={styles.weatherRow}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Row>
            <Col span={12}>
              <Row align="middle" style={{ height: "100%" }}>
                <span
                  className={`${styles.temperature} ${
                    temperature.toFixed(0).indexOf(1) === 0
                      ? styles.tempForOne
                      : null
                  }`}
                >
                  {temperature.toFixed(0)}°
                </span>
              </Row>
            </Col>
            <Col span={12}>
              <Row align="middle" style={{ height: "100%" }}>
                <WeatherIcon
                  className={styles.weatherImage}
                  group={group}
                  description={description}
                  dayPart={dayPart}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <span
              className={`${styles.cityName} ${
                temperature.toFixed(0).indexOf(1) === 0
                  ? styles.cityNameForTempOne
                  : null
              }`}
            >
              {cityName}
            </span>
          </Row>
        </div>
      </div>
    </Row>
  </div>
);

WeatherWidgetFullScreenPortraitView.propTypes = {
  cityName: PropTypes.string.isRequired,
  time: PropTypes.shape({}).isRequired, // should be moment propTypes
  enableClock: PropTypes.bool.isRequired,
  temperature: PropTypes.number.isRequired,
};

WidgetViewProvider.registerWidgetView(
  {
    widget: "weather",
    orientations: ["portrait"],
    zoneTypes: ["monitor-content--fullscreen"],
  },
  WeatherWidgetFullScreenPortraitView
);
