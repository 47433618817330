import { observable, action } from "mobx";
import WeatherCondition from "./WeatherCondition";
import { DateTime } from "luxon";

/**
 * @see https://openweathermap.org/current
 */
export default class Weather {
    /**
     * @var {number}
     */
    @observable temperature;

    /**
     * @var {number}
     */
    @observable temperatureFeelsLike;

    /**
     * @var {number}
     */
    @observable minTemperature;

    /**
     * @var {number}
     */
    @observable maxTemperature;

    /**
     * @var {number}
     */
    @observable pressure;

    /**
     * @var {number}
     */
    @observable humidity;

    /**
     * @var {number}
     */
    @observable windSpeed;

    /**
     * @var {number}
     */
    @observable windDegree;

    /**
     * @var {string}
     */
    @observable cityName;

    /**
     * @var {Moment}
     */
    @observable sunrise;

    /**
     * @var {Moment}
     */
    @observable sunset;

    /**
     * @var {WeatherCondition}
     */
    @observable condition = new WeatherCondition();

    @action
    fromJson(data, timezone) {
        this.temperature = data.main.temp;
        this.temperatureFeelsLike = data.main.feels_like;
        this.minTemperature = data.main.temp_min;
        this.maxTemperature = data.main.temp_max;
        this.pressure = data.main.pressure;
        this.humidity = data.main.humidity;
        this.windSpeed = data.wind.speed;
        this.windDegree = data.wind.deg;
        this.cityName = data.name;
        this.sunrise = DateTime.fromSeconds(data.sys.sunrise).setLocale('hu').setZone(timezone);
        this.sunset = DateTime.fromSeconds(data.sys.sunset).setLocale('hu').setZone(timezone);
        this.condition.id = data.weather[0].id;
        this.condition.group = data.weather[0].main;
        this.condition.description = data.weather[0].description;
    } 
}