import { observable } from "mobx";
import { WidgetProvider } from "../ui/widgets";

class Widget {
  name = "unknown-widget";

  /**
   * Duration in milliseconds
   *
   * @var {number}
   */
  @observable
  duration = null;

  /**
   * @var {{}}
   */
  @observable
  props;

  /**
   * @param {string} name
   * @param {number} duration
   * @param {object} props
   * @param {object} component
   */
  constructor(name, duration, props) {
    this.name = name;
    this.duration = duration;
    this.props = props;
  }

  /**
   * @param {{
   *  duration: number, // lifetime of widget in milliseconds.
   *  type: string, // unique slug of widget type.
   *  settings: object // widget specific settings.
   * }} data
   * @return {this}
   */
  static createInstanceFromJson(data) {
    const widgetName = data.type;
    const widget = WidgetProvider.getWidget(widgetName);

    if (!widget) {
      throw new Error(
        `Widget is not registered!: ${widgetName}. Check ui/widget/index.js\n`
      );
    }
    const props = new widget.props();
    props.name = widgetName;
    props.fromJson(data.settings);

    const instance = new Widget(widgetName, data.duration, props);

    return instance;
  }
}

export default Widget;
