import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col  } from 'antd';

import styles from './ClockWidgetMainView.module.less';
import WidgetViewProvider from '../../../WidgetViewProvider';

const ClockWidgetMainView = ({
    time,
    ...props
}) => (
    <div className={styles.root} {...props}>
        <div className={styles.mainContainer}>
            <div className={styles.verticalLine}></div>
            <Row style={{ height: '100%' }} align="middle">
            <Col span={12}>
                <div className={styles.container}>
                    <Row>
                        <span className={styles.date}>
                            {time.toFormat('LLLL dd')}.
                        </span>
                    </Row>
                    <Row>
                        <span className={styles.day}>
                            {time.toFormat('cccc')}
                        </span>
                    </Row>
                </div>
            </Col>
            <Col span={12}>
                <div className={styles.container}>
                <Row>
                    <span className={styles.time}>
                        {time.toFormat('HH:mm')}
                    </span>
                </Row>
                </div>
            </Col>
            </Row>
        </div>
    </div>
);

ClockWidgetMainView.propTypes = {
    time: PropTypes.shape({}).isRequired
};

WidgetViewProvider.registerWidgetView({
    widget: 'time',
    orientations: ['landscape'],
    zoneTypes: ['monitor-content--hd'],
}, ClockWidgetMainView);