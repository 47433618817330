import React from 'react';
import {Widget} from '../../Widget/index';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styles from './ClockWidget.module.less';
import ClockWidgetProps from '../models/ClockWidgetProps';
import widgetRegister from '../../widgetRegister';
import './views';

@widgetRegister({name: 'time', props: ClockWidgetProps})
@observer
class ClockWidget extends Widget {
    static propTypes = {
        ...Widget.PropTypes,
        time: PropTypes.shape({}).isRequired,
        nameDaytext: PropTypes.string,
        bankHolidayText: PropTypes.string,
    };

    render() {
        return <div className={styles.root}>
            {this.getView({
                time: this.props.time,
                nameDaytext: this.props.nameDaytext,
                bankHolidayText: this.props.bankHolidayText,
                className: styles.root,
            })}
        </div>;
    }
}

export default ClockWidget;