import Api from '../Api';
import axios from 'axios';
import { compatibleApiVersion } from '../../package.json';

/**
 * @return {{
 *  background: string,
 *  layout: {
 *      container_class: string,
 *      orientation: string,
 *      zones: {
 *          container_class: string,
 *          id: number
 *      }[]
 *  }}
 * }
 */
export const getSettings = async (screenId) => {
    if (screenId.indexOf('demo-') === 0) {
        const response = await axios.get(`/fixtures/${screenId}.json`);
        const settings = await response.data.settings;
        settings.version = compatibleApiVersion;
        return settings;
    }

    const response = await Api.get(`/site/screen-settings?screen_id=${screenId}`);
    return await response.data;
};
