import { observable } from "mobx";

export default class Layout {
    /**
     * @var {string}
     */
    name;

    /**
     * @var {string}
     */
    @observable
    background;

    /**
     * @var {string}
     */
    @observable
    orientation = 'landscape';

    /**
     * A collection of zones to hold zone information
     * 
     * @var {{[zoneId: number]: Zone}}
     */
    @observable
    zones = {};

    /**
     * 
     * @param {string} name 
     * @param {string} orientation 
     * @param {{[zoneId: number]: Zone}} zones 
     * @param {string} background 
     */
    constructor(name, orientation, zones, background) {
        this.name = name;
        this.orientation = orientation;
        this.background = background;
        this.zones = zones;

        // attach all zones to this layout.
        Object.keys(this.zones).forEach(zoneId => this.zones[zoneId].layout = this);
    }
}