import { observable } from "mobx";

/**
 * Just to have a common ancestor and for later if we want to add some common props...
 */
class WidgetProps {
    /**
     * @var bool
     */
    @observable visible = true;

    /**
     * @var bool
     */
    @observable loading = false;

    /**
     * @retrun {object}
     */
    toJson() {
        throw new Error('Concrete widgetProps must implement toJson()');
    }

    /**
     * @param {object} json
     * @retrun {this}
     */
    fromJson(json) {
        throw new Error('Concrete widgetProps must implement fromJson()');
    }
}

export default WidgetProps;