import React from 'react';
import PropTypes from 'prop-types';

import styles from './ImageWidget.module.less';
import { WidgetViewProvider } from '../../..';

const ImageWidgetView = ({
    imageFile,
    ...props
}) => (
      <img
        src={imageFile}
        className={styles.container}
        alt=""
        {...props}
      />
);

ImageWidgetView.propTypes = {
    imageFile: PropTypes.string.isRequired
};

WidgetViewProvider.registerWidgetView({
  widget: 'image',
  default: true,
}, ImageWidgetView);