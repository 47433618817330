import React from "react";
import { Row } from "antd";

import styles from "./TextWidgetPortraitFullScreenView.module.less";
import templates from "./TextWidgetTemplates.module.less";
import WidgetViewProvider from "../../../WidgetViewProvider";

const TextWidgetPortraitFullScreenView = ({
  headline,
  subHeadline,
  template,
  text,
}) => (
  <Row
    className={`${styles.root} ${templates[`fullScreen-${template.background}-bg`]} ${
      templates[`${template.text}-text`]
    }`}
  >
    <div className={styles.container}>
      <Row style={{ marginBottom: "4vh" }}>
        <span className={styles.headLine}>{headline}</span>
      </Row>
      <Row style={{ marginBottom: "4vh" }}>
        <span className={styles.subHeadLine}>{subHeadline}</span>
      </Row>
      <Row>
        <span className={styles.text}>{text}</span>
      </Row>
    </div>
  </Row>
);

WidgetViewProvider.registerWidgetView(
  {
    widget: "text",
    orientations: ["portrait"],
    zoneTypes: ["monitor-content--fullscreen"],
  },
  TextWidgetPortraitFullScreenView
);
