import { observable, computed, action } from "mobx";

/**
 * A zone is used to render a widget
 * (only one widget/zone at a time)
 * in different 'zones' of the currently selected layout!
 */
export default class Zone {
    /**
     * @var {number}
     */
    @observable
    id;

    /**
     * @var {string}
     */
    @observable
    type;

    /**
     * The parent layout, which holds this zone.
     * 
     * There is a OneToMany relation between Layout and Zone.
     * 
     * @var {Layout}
     */
    @observable
    layout;

    /**
     * @var {Widget[]}
     */
    @observable
    widgets = [];

    /**
     * @var {bool}
     */
    @observable
    loading = false;

    /**
     * @var {number}
     */
    @observable
    currentlyPlayedWidgetIndex;

    /**
     * @param {number} id 
     * @param {string} type 
     * @param {Widget[]} widgets 
     * @param {number} currentlyPlayedWidgetIndex 
     */
    constructor(id, type) {
        this.id = id;
        this.type = type;
    }

    /**
     * @param {number} newIndex
     * @return {void}
     */
    @action
    setCurrentlyPlayedWidgetIndex(newIndex) {
        const lastWidgetIndex = this.widgets.length -1;
        if (newIndex <= lastWidgetIndex && newIndex >= 0) {
            this.currentlyPlayedWidgetIndex = newIndex;
            return;
        }

        this.currentlyPlayedWidgetIndex = 0;
    }

    /**
     * @return {Widget}
     */
    @computed
    get currentlyPlayedWidget() {
        if (this.widgets.length === 0 || typeof this.widgets[this.currentlyPlayedWidgetIndex] === undefined) {
            return null;
        }
        console.log(`%c Widget playing '${this.widgets[this.currentlyPlayedWidgetIndex].name}' in zone '${this.id}-${this.type}'.`, `color: ${zoneColors[this.id]}`, this.widgets[this.currentlyPlayedWidgetIndex].props);
        return this.widgets[this.currentlyPlayedWidgetIndex];
    }

    @computed
    get nextWidgetIndex() {
        const lastWidgetIndex = this.widgets.length -1;
        let nextWidgetIndex = this.currentlyPlayedWidgetIndex + 1;
        if (nextWidgetIndex > lastWidgetIndex) {
            nextWidgetIndex = 0;
        }

        return nextWidgetIndex;
    }

    @action
    playNextWidget() {
        this.setCurrentlyPlayedWidgetIndex(this.nextWidgetIndex);
    }
}

/**
 * Just for visualization for each zone, to better follow the logs and debug.
 */
const zoneColors = {
    1: '#4285f4',
    2: '#ea4335',
    3: '#fbc034',
    4: '#34a853',
    5: '#007acc'
};