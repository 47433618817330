import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Widget } from '../../Widget/index';
import TextWidgetProps from '../models/TextWidgetProps';
import widgetRegister from '../../widgetRegister';
import './views';

@observer
@widgetRegister({name: 'text', props: TextWidgetProps})
class TextWidget extends Widget {
  static propTypes = {
    ...Widget.PropTypes,
    headline: PropTypes.string,
    subHeadline: PropTypes.string,
    template: PropTypes.shape({
      background: PropTypes.string,
      text: PropTypes.string
    }).isRequired,
    text: PropTypes.string.isRequired
  };

  render() {
    console.log(this.props);
    return this.getView({
      headline: this.props.headline,
      subHeadline: this.props.subHeadline,
      template: this.props.template,
      text: this.props.text
    });
  }
}

export default TextWidget;
