import { WidgetProps } from "../../Widget/index";
import { action } from "mobx";

const { observable } = require("mobx");

class TextWidgetProps extends WidgetProps {
    /**
     * @var {string}
     */
    @observable headline;

    /**
     * @var {string}
     */
    @observable subHeadline;

    /**
     * @var {string}
     */
    @observable template;

    /**
     * @var {string}
     */
    @observable text;

    /**
     * @retrun {object}
     */
    toJson() {
        return {
            headline: this.headline,
            subheadline: this.subHeadline,
            template: this.template,
            text: this.text,
        };
    }

    /**
     * @param {object} json
     * @retrun {this}
     */
    @action fromJson(props) {
        this.headline = props.headline;
        this.subHeadline = props.subheadline;
        try{
            const templateString = props.selected_template_editor_classes.split(' ');
            this.template = {
                background: templateString[0].split('-')[2],
                text: templateString[1].split('-')[1]
            };
        } catch {
            this.template = {
                background: 'green',
                text: 'white'
            };
        }
        this.text = props.text;

        return this;
    }
}

export default TextWidgetProps;