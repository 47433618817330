import React, { memo } from "react";
import PropTypes from 'prop-types';

import { ReactComponent as IconSquall } from '../../assets/icons/wi-windy.svg';
import { ReactComponent as IconTornado } from '../../assets/icons/wi-tornado.svg';
import { ReactComponent as IconDayClouds } from '../../assets/icons/wi-day-cloudy.svg';
import { ReactComponent as IconNightClouds } from '../../assets/icons/wi-night-alt-cloudy.svg';
import { ReactComponent as IconNightClear } from '../../assets/icons/wi-night-clear.svg';
import { ReactComponent as IconDayClear } from '../../assets/icons/wi-day-sunny.svg';
import { ReactComponent as IconDaySnow } from '../../assets/icons/wi-day-snow.svg';
import { ReactComponent as IconNightSnow } from '../../assets/icons/wi-night-alt-snow.svg';
import { ReactComponent as IconDayThunderstorm } from '../../assets/icons/wi-day-thunderstorm.svg';
import { ReactComponent as IconNightThunderstorm } from '../../assets/icons/wi-night-alt-thunderstorm.svg';
import { ReactComponent as IconDayDrizzle } from '../../assets/icons/wi-day-showers.svg';
import { ReactComponent as IconNightDrizzle } from '../../assets/icons/wi-night-alt-showers.svg';
import { ReactComponent as IconDayRain } from '../../assets/icons/wi-day-rain.svg';
import { ReactComponent as IconNightRain } from '../../assets/icons/wi-night-alt-rain.svg';
import { ReactComponent as IconSmoke } from '../../assets/icons/wi-smoke.svg';
import { ReactComponent as IconHaze } from '../../assets/icons/wi-day-haze.svg';
import { ReactComponent as IconDust } from '../../assets/icons/wi-dust.svg';
import { ReactComponent as IconFog } from '../../assets/icons/wi-day-fog.svg';
import { ReactComponent as IconNightFog } from '../../assets/icons/wi-night-fog.svg';
import { ReactComponent as IconSandstorm } from '../../assets/icons/wi-sandstorm.svg';
import { ReactComponent as IconDayCloudyGusts } from '../../assets/icons/wi-day-cloudy-gusts.svg';
import { ReactComponent as IconNightCloudyGusts } from '../../assets/icons/wi-night-alt-cloudy-gusts.svg';

const weatherIcon = ({
    group,
    description,
    dayPart,
    ...props
}) => {
    props = {
        ...props,
        fill: '#FFFFFF'
    }
    switch(group.toLowerCase()) {
        case 'squall':
            return <IconSquall {...props} />;
        case 'tornado':
            return <IconTornado {...props} />;
        case 'clouds':
            if (dayPart === 'night') {
                return <IconNightClouds {...props} />
            }
            return <IconDayClouds {...props} />;
        case 'clear':
            if (dayPart === 'night') {
                return <IconNightClear {...props} />;
            }
            return <IconDayClear {...props} />;
        case 'snow':
            if (dayPart === 'night') {
                return <IconNightSnow {...props} />
            }
            return <IconDaySnow {...props} />;
        case 'thunderstorm':
            if (dayPart === 'night') {
                return <IconNightThunderstorm {...props} />
            }
            return <IconDayThunderstorm {...props} />;
        case 'drizzle':
            if (dayPart === 'night') {
                return <IconNightDrizzle {...props} />
            }
            return <IconDayDrizzle {...props} />;
        case 'rain':
            if (dayPart === 'night') {
                return <IconNightRain {...props} />
            }
            return <IconDayRain {...props} />;
        case 'smoke':
            return <IconSmoke {...props} />;
        case 'haze':
            return <IconHaze {...props} />;
        case 'dust':
            return <IconDust {...props} />;
        case 'fog':
            if (dayPart === 'night') {
                return <IconNightFog {...props} />;
            }
            return <IconFog {...props} />;
        case 'sandstorm':
            return <IconSandstorm {...props} />;
        default:
            if (dayPart === 'night') {
                return <IconNightCloudyGusts {...props} />;
            }
            return <IconDayCloudyGusts {...props} />;
    }
};

weatherIcon.propTypes = {
    group: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    dayPart: PropTypes.oneOf(['night', 'daytime']).isRequired,
};

weatherIcon.defaultProps = {
    dayPart: 'daytime'
};

export default memo(weatherIcon);