import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col  } from 'antd';

import styles from './WeatherWidgetMainView.module.less';
import WidgetViewProvider from '../../../WidgetViewProvider';
import WeatherIcon from '../atoms/WeatherIcon';

const WeatherWidgetMainView = ({
    cityName,
    time,
    dayPart,
    enableClock,
    temperature,
    group,
    description,
    ...props
}) => (
    <div className={styles.mainContainer} {...props}>
        <div className={styles.verticalLine}></div>
        <Row style={{height: '100%'}} align="middle">
        <Col span={12}>
            <div className={styles.container}>
                <Row>
                    <span className={styles.date}>
                        {time.toFormat('LLLL dd')}.
                </span>
                </Row>
                <Row>
                    <span className={styles.day}>
                        {time.toFormat('cccc')}
                    </span>
                </Row>
            {enableClock && (
                <Row>
                    <span className={`${styles.time} ${time.toFormat('HH:mm').indexOf(1) === 0 ? styles.timeForOne : null} `}>
                        {time.toFormat('HH:mm')}
                    </span>
                </Row>
            )}
            </div>
        </Col>
        <Col span={12}>
            <div className={styles.container}>
            <Row>
                <Col span={12} className={styles.center}>
                    <span className={`${styles.temperature} ${temperature.toFixed(0).indexOf(1) === 0 ? styles.tempForOne : null}`}>
                        {temperature.toFixed(0)}°
                    </span>
                </Col>
                <Col span={12} className={styles.center}>
                    <WeatherIcon className={styles.weatherImage} group={group} description={description} dayPart={dayPart} />
                </Col>
            </Row>
            <Row>
                <span className={styles.cityName}>
                    {cityName}
                </span>
            </Row>
            </div>
        </Col>
        </Row>
    </div>
);

WeatherWidgetMainView.propTypes = {
    cityName: PropTypes.string.isRequired,
    time: PropTypes.shape({}).isRequired, // should be moment propTypes
    enableClock: PropTypes.bool.isRequired,
    temperature: PropTypes.number.isRequired
};

WidgetViewProvider.registerWidgetView({
    widget: 'weather',
    orientations: ['landscape'],
    zoneTypes: ['monitor-content--hd'],
}, WeatherWidgetMainView);