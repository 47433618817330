import React from 'react';
import { Row } from 'antd';

import styles from './TextWidgetDefaultView.module.less';
import templates from './TextWidgetTemplates.module.less';
import WidgetViewProvider from '../../../WidgetViewProvider';

const TextWidgetView = ({
    headline,
    template,
    text
}) => (
    <Row className={`${styles.root} ${templates[`y-${template.background}-bg`]} ${templates[`${template.text}-text`]}`}>
        <div className={styles.container}>
            <Row style={{marginBottom: '3vh'}}>
                <span className={styles.headLine}>
                    {headline}
                </span>
            </Row>
            <Row style={{ marginBottom: '3vh' }}>
                <span className={styles.text}>
                    {text}
                </span>
            </Row>
        </div>
    </Row>
);

WidgetViewProvider.registerWidgetView({
    widget: 'text',
    default: true,
}, TextWidgetView);