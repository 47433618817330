import React from 'react';
import { Row } from 'antd';

import styles from './TextWidgetMainView.module.less';
import templates from './TextWidgetTemplates.module.less';
import WidgetViewProvider from '../../../WidgetViewProvider';

const TextWidgetMainView = ({
  headline,
  subHeadline,
  template,
  text
}) => (
  <Row className={`${styles.root} ${templates[`hd-${template.background}-bg`]} ${templates[`${template.text}-text`]}`}>
    <div className={styles.container}>
      <Row style={{ marginBottom: '6vh', width: '52vw' }}>
        <span className={styles.headLine}>
          {headline}
        </span>
      </Row>
      <Row style={{ marginBottom: '6vh', width: '52vw' }}>
        <span className={styles.subHeadLine}>
          {subHeadline}
        </span>
      </Row>
      <Row>
        <span className={styles.text}>
          {text}
        </span>
      </Row>
    </div>
  </Row>
);

WidgetViewProvider.registerWidgetView({
  widget: 'text',
  orientations: ['landscape'],
  zoneTypes: ['monitor-content--hd']
}, TextWidgetMainView);