import React, { memo } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './ZoneLoader.module.less';

export default memo(({
    enabled,
    ...props
}) => (
    enabled && (
        <div className={styles.root} {...props}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}/>
        </div>
    )
));