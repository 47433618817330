import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";

import styles from "./WeatherWidgetSubView.module.less";
import WidgetViewProvider from "../../../WidgetViewProvider";
import WeatherIcon from "../atoms/WeatherIcon";

const WeatherWidgetSubView = ({
  cityName,
  time,
  dayPart,
  enableClock,
  temperature,
  group,
  description,
  ...props
}) => (
  <div {...props}>
    <div className={styles.mainContainer}>
      <div className={styles.verticalLine}></div>
      <Row style={{ height: "100%" }} align="middle">
        <Col flex="50vw" style={{ height: "100%" }}>
          <Row style={{ height: "100%" }} align="middle">
            <Col span={24}>
              <Row style={{ height: "100%" }} align="middle" justify="center">
                <span className={styles.date}>{time.toFormat("LLLL dd")}.</span>
                <span className={styles.day}>
                  &nbsp;{time.toFormat("cccc")}
                </span>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col flex="50vw" style={{ height: "100%" }}>
          <Row style={{ height: "100%" }} align="middle">
            <Col span={24}>
              <Row style={{ height: "100%" }} align="middle" justify="center">
                <span className={styles.temperature}>
                  {temperature.toFixed(0)}°&nbsp;
                </span>
                <WeatherIcon
                  className={styles.weatherImage}
                  group={group}
                  description={description}
                  dayPart={dayPart}
                />
                <span className={styles.cityName}>&nbsp;&nbsp;{cityName}</span>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </div>
);

WeatherWidgetSubView.propTypes = {
  cityName: PropTypes.string.isRequired,
  time: PropTypes.shape({}).isRequired, // should be moment propTypes
  enableClock: PropTypes.bool.isRequired,
  temperature: PropTypes.number.isRequired,
};

WidgetViewProvider.registerWidgetView(
  {
    widget: "weather",
    orientations: ["portrait"],
    zoneTypes: ["monitor-content--widget"],
  },
  WeatherWidgetSubView
);
