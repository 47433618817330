import React from 'react';
import { Col, Row } from 'antd';
import Zone from '../../components/Zone/Zone';
import LayoutProvider from '../LayoutProvider';

const fullScreen = () => (
    <Row style={{height: '100vh'}}>
        <Col flex="100vw" style={{height: '100vh'}}>
            <Zone zone={0} fullscreenToggler/>
        </Col>
    </Row>
);

LayoutProvider.registerLayout(["landscape-0", "portrait-0"], fullScreen);