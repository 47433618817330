import { observable, computed, action } from "mobx";
import { compatibleApiVersion } from "../../package.json";
import Widget from "./Widget";
import Layout from "./Layout";
import Zone from "./Zone";
import { DateTime } from "luxon";

export default class Settings {
  /**
   * @var {Layout}
   */
  @observable
  layout;

  /**
   * Number of milliseconds after the system must fetch
   * backend api for fresh 'playlist'.
   *
   * @var {number}
   */
  @observable
  nextMandatoryRefresh;

  /**
   * @var {string}
   */
  @observable
  version;

  /**
   * @var {string}
   */
  compatibleApiVersion = compatibleApiVersion.toString();

  /**
   *
   * @param {Layout} layout
   * @param {string} version
   */
  constructor(layout, version) {
    this.layout = layout;
    this.version = version.toString();
  }

  @computed
  get isCompatibleApiVersion() {
    return this.compatibleApiVersion === this.version;
  }

  /**
   * @param {{
   *  background: string,
   *  layout: {
   *      container_class: string,
   *      orientation: [string],
   *      zones: {
   *          container_class: string,
   *          id: number
   *      }[]
   *  },
   *  next_mandatory_refresh: number, // utc timestamp.
   *  playlists: {
   *      zone_id: number,
   *      resume_playing_from: number,
   *      widgets: {
   *          duration: number, // lifetime of widget in milliseconds.
   *          type: string, // unique slug of widget type.
   *          settings: object // widget specific settings.
   *      }[]
   *  }[]}
   * } jsonData
   */
  static createInstanceFromJson(jsonData) {
    const settings = new Settings(
      new Layout(
        `${
          jsonData.layout.orientation
        }-${jsonData.layout.container_class.replace("layout-", "")}`,
        jsonData.layout.orientation,
        jsonData.layout.zones.map((zone) => {
          return new Zone(zone.id, zone.container_class);
        }),
        jsonData.background
      ),
      jsonData.version
    );
    settings.setNextMandatoryRefreshFromUtcTimestamp(
      jsonData.next_mandatory_refresh
    );

    if (jsonData.playlists) {
      settings.resetLayoutZoneWidgetsFromJson(jsonData.playlists);
    }

    return settings;
  }

  @action
  resetLayoutZoneWidgetsFromJson(zones) {
    zones.forEach((zone) => {
      const zoneInstance = this.layout.zones.find(
        (zoneInstanceValue) => zoneInstanceValue.id === zone.zone_id
      );
      if (!zoneInstance) {
        return;
      }

      zoneInstance.currentlyPlayedWidgetIndex = zone.resume_playing_from;
      zoneInstance.widgets = [];
      for (const widgetJson of zone.widgets) {
        try {
          const widget = Widget.createInstanceFromJson(widgetJson);
          zoneInstance.widgets.push(widget);
        } catch (error) {
          console.error(error);
          // just ignore the widget if not able to create it
        }
      }
    });
  }

  /**
   * @param {number} utcTimestamp
   */
  setNextMandatoryRefreshFromUtcTimestamp(utcTimestamp) {
    const nextRefresh = DateTime.fromSeconds(utcTimestamp);
    const now = DateTime.utc();
    this.nextMandatoryRefresh = nextRefresh.toUTC().diff(now).milliseconds;
  }
}
