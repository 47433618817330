import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";

import styles from "./ClockWidgetPortraitView.module.less";
import WidgetViewProvider from "../../../WidgetViewProvider";

const ClockWidgetPortraitView = ({ time, ...props }) => (
  <div className={styles.root} {...props}>
    <div className={styles.mainContainer}>
      <div className={styles.verticalLine} />
      <Row style={{ height: "100%" }} align="middle">
        <Col span={12}>
          <div className={styles.container}>
            <Row>
              <span className={styles.date}>{time.toFormat("LLLL dd")}.</span>
            </Row>
            <Row>
              <span className={styles.day}>{time.toFormat("cccc")}</span>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.container}>
            <Row>
              <span className={styles.time}>{time.toFormat("HH:mm")}</span>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

ClockWidgetPortraitView.propTypes = {
  time: PropTypes.shape({}).isRequired,
};

WidgetViewProvider.registerWidgetView(
  {
    widget: "time",
    orientations: ["portrait"],
  },
  ClockWidgetPortraitView
);
