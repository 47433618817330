import {observable, action, when} from "mobx";
import { getSettings, getPlaylist } from "../services";
import { Settings } from "../models";
import { reloadPage } from "../utils/locationHelper";
import { getScreenRefresh } from "../services/ScreenRefreshService";

export default class AppStore {
	/**
	 * @var {string}
	 */
	screenId;

	/**
	 * @var {bool}
	 */
	isPreview = false;

	/**
	 * @var {bool}
	 */
	@observable
  loading = true;

	/**
	 * @var {Settings}
	 */
	@observable
	settings;

	/**
	 * @var {{
	 * 	status: number,
	 * 	message: string
	 * }}
	 */
	@observable
	error;

	/**
	 * Holds the timeouts for widget queue of zones
	 *
	 * @var {object}
	 */
	widgetZoneTimeouts = {};

	mandatoryRefreshTimeout;

	/**
	 * Ask the backend if needs to refresh the playlist or not.
	 *
	 * @var {number}
	 */
	screenRefreshTime = 30000;

    @observable
    isMandatoryRefreshing = false;

	constructor(screenId, isPreview) {
		this.screenId = screenId;
		this.isPreview = isPreview;
	}

	@action
	async init() {
		this.loading = true;
		try {
			const settingsData = await getSettings(this.screenId);
			const playlistData = await getPlaylist(this.screenId, this.isPreview);
			this.settings = Settings.createInstanceFromJson({
				...settingsData,
				...playlistData
			});

			if (!this.settings.isCompatibleApiVersion) {
				reloadPage(10000);
				this.error = {
					status: 500,
					message: 'Nem kompatibilis api verzió! 1 perc múlva újra próbáljuk!'
				};
				throw new Error();
			}

			this.scheduleMandatoryRefresh(this.settings.nextMandatoryRefresh);
			this.scheduleWidgetQueues();
			this.scheduleRefreshScreen();
		} catch(error) {
			this.settings = undefined;
			this.playlist = undefined;
			if (!this.error) {
				this.error = {
					status: 404,
					message: 'A keresett loop screen nem található!'
				};
			}
		} finally {
			setTimeout(() => {
				this.loading = false;
			}, 800);
		}
	}

	@action
	async scheduleRefreshScreen() {
        try {
		    const shouldScreenRefresh = await getScreenRefresh(this.screenId, this.isPreview);
            if (shouldScreenRefresh) {
                await this.stopMandatoryRefresh()

                await this.refreshPlaylist();
                this.scheduleMandatoryRefresh(this.settings.nextMandatoryRefresh);
            }
        } catch (e) {
          throw e;
        } finally {
            setTimeout(() => {
                this.scheduleRefreshScreen();
            }, this.screenRefreshTime);
        }
	}

    async stopMandatoryRefresh() {
        await when(() => !this.isMandatoryRefreshing);
        clearTimeout(this.mandatoryRefreshTimeout)
    }

	@action
	scheduleMandatoryRefresh(delay) {
		if (delay < 1000) {
			return;
		}

		const mins = delay / 1000 / 60;
		console.log(`Should refresh the playlist after ${mins.toFixed(0)} minutes!`);

		this.mandatoryRefreshTimeout = setTimeout(async () => {
            this.isMandatoryRefreshing = true;
            try {
			    await this.refreshPlaylist();
                this.scheduleMandatoryRefresh(this.settings.nextMandatoryRefresh);
            } catch (e) {
                this.scheduleMandatoryRefresh(this.screenRefreshTime);
                throw e;
            } finally {
                this.isMandatoryRefreshing = false;
            }
		}, delay);
	}

	@action
	async refreshPlaylist() {
		const playlistData = await getPlaylist(this.screenId, this.isPreview);
		this.settings.setNextMandatoryRefreshFromUtcTimestamp(playlistData.next_mandatory_refresh);
		this.settings.version = playlistData.version.toString();

		if (!this.settings.isCompatibleApiVersion) {
			reloadPage();
			return;
		}
		this.resetWidgetQueue();

		setTimeout(() => {
			this.settings.resetLayoutZoneWidgetsFromJson(playlistData.playlists);
			this.scheduleWidgetQueues();
		}, 500);
	}

	@action
	resetWidgetQueue() {
		Object.keys(this.settings.layout.zones).forEach(zoneId => {
			if (!this.settings.layout.zones[zoneId].currentlyPlayedWidget) {
				return;
			}
			this.settings.layout.zones[zoneId].currentlyPlayedWidget.props.visible = false;
		});
		Object.keys(this.widgetZoneTimeouts).forEach(timeout => {
			clearTimeout(this.widgetZoneTimeouts[timeout]);
		});
	}

	@action
	scheduleWidgetQueues() {
        Object.keys(this.settings.layout.zones).forEach(zoneId => {
			this.scheaduleWidgetQueue(this.settings.layout.zones[zoneId]);
        });
	}

	@action
	scheaduleWidgetQueue(zone) {
		if (this.error || zone.currentlyPlayedWidget === null || !zone.currentlyPlayedWidget.duration || zone.currentlyPlayedWidget.duration < 100) {
			return;
		}
		console.log(`%c Next widget '${zone.widgets[zone.nextWidgetIndex].name}' scheaduled for zone '${zone.id}-${zone.type}' in ${zone.currentlyPlayedWidget.duration} ms.`, `color: ${zone.zoneColor}`);

    this.prefetchNextWidgetAssets(zone);

		this.widgetZoneTimeouts[zone.id] = setTimeout(() => {
			if (this.error || zone.currentlyPlayedWidget === null) {
				return;
			}
			zone.currentlyPlayedWidget.props.visible = false;
			setTimeout(() => {
				zone.playNextWidget();
				this.scheaduleWidgetQueue(zone);
				zone.currentlyPlayedWidget.props.visible = true;
			}, 1500); // delay to let fadeOut animation finish before fadeIn
		}, (zone.currentlyPlayedWidget.duration));
	}

  async prefetchNextWidgetAssets(zone)
  {
    console.log('Prefetching assets for zone' + zone.nextWidgetIndex);
    const widget = zone.widgets[zone.nextWidgetIndex];

    if (widget.name === 'image')
    {
      console.log('Prefetching image from ' + widget.props.imageFile);
      const tempImg = new Image()
      tempImg.src=widget.props.imageFile
      tempImg.onload = () => {
        console.log(widget.props.imageFile + ' image loaded');
      };
    }
  }
}
