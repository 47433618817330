import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col  } from 'antd';

import styles from './WeatherWidgetBottomView.module.less';
import { WidgetViewProvider } from '../../..';
import WeatherIcon from '../atoms/WeatherIcon';

const WeatherWidgetBottomView = ({
    cityName,
    time,
    dayPart,
    enableClock,
    temperature,
    group,
    description,
    ...props
}) => (
  <div {...props}>
    <div className={styles.mainContainer}>
      <div className={styles.verticalLine}></div>
      <Row style={{ height: '100%' }} align="middle">
        <Col span={12}>
          <div className={styles.dateContainer}>
            <Row>
              <span className={styles.date}>
                {time.toFormat('LLLL dd')}.
              </span>
            </Row>
            <Row>
              <span className={styles.day}>
                {time.toFormat('cccc')}
              </span>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.container}>
            <Row justify="center">
              <span className={`${styles.temperature} ${temperature.toFixed(0).indexOf(1) === 0 ? styles.tempForOne : null}`}>
                {temperature.toFixed(0)}°
              </span>
              <WeatherIcon className={styles.weatherImage} group={group} description={description} dayPart={dayPart} />
            </Row>
            <Row>
              <span className={styles.cityName}>
                {cityName}
              </span>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

WeatherWidgetBottomView.propTypes = {
    cityName: PropTypes.string.isRequired,
    time: PropTypes.shape({}).isRequired, // should be moment propTypes
    enableClock: PropTypes.bool.isRequired,
    temperature: PropTypes.number.isRequired
};

WidgetViewProvider.registerWidgetView({
  widget: 'weather',
  orientations: ['landscape'],
  zoneTypes: ['monitor-content--x'],
}, WeatherWidgetBottomView);