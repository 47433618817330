/**
 * Function will reload page instantly or after $delay milliseonds later.
 * 
 * @param {number} delay Milliseconds to delay before page reload
 */
export const reloadPage = delay => {
    if (delay === undefined || !delay) {
        window.location.reload(false);
        return;
    }
    
    setTimeout(async () => {
        reloadPage();
    }, delay);
};