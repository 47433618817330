import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import styles from './WeatherWidgetBottomWithClockView.module.less';
import { WidgetViewProvider } from '../../..';
import WeatherIcon from '../atoms/WeatherIcon';

const WeatherWidgetBottomWithCloclView = ({
  cityName,
  time,
  dayPart,
  enableClock,
  temperature,
  group,
  description,
  ...props
}) => (
  <div {...props}>
    <div className={styles.mainContainer}>
        <div className={styles.verticalLine}></div>
        <Row style={{ height: '100%' }} align="middle">
          <Col flex="70vw" style={{height: '100%'}}>
            <Row style={{height: '100%'}} align="middle">
              <Col span={12}>
                <div className={styles.dateContainer}>
                  <Row>
                    <span className={styles.date}>
                      {time.toFormat('LLLL dd')}.
                    </span>
                  </Row>
                  <Row>
                    <span className={styles.day}>
                      {time.toFormat('cccc')}
                    </span>
                  </Row>
                </div>
              </Col>
              <Col span={12}>
                <div className={styles.dateContainer}>
                  <Row>
                    <span className={styles.time}>
                      {time.toFormat('HH:mm')}
                    </span>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col flex="30vw" style={{height: '100%'}}>
            <Row style={{height: '100%'}} align="middle">
                <Col span={24}>
                  <div className={styles.container}>
                    <Row>
                      <Col span={24}>
                        <span className={styles.temperature}>
                          {temperature.toFixed(0)}°
                          <WeatherIcon className={styles.weatherImage} group={group} description={description} dayPart={dayPart} />
                        </span>
                      </Col>
                    {/* <Col span={12} className={[styles.alignMiddle]}>
                        <Icon className={styles.weatherImage} />
                    </Col> */}
                    </Row>
                    <Row>
                      <span className={styles.cityName}>
                        {cityName}
                      </span>
                    </Row>
                  </div>
                </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
);

WeatherWidgetBottomWithCloclView.propTypes = {
  cityName: PropTypes.string.isRequired,
  time: PropTypes.shape({}).isRequired, // should be moment propTypes
  temperature: PropTypes.number.isRequired
};

WidgetViewProvider.registerWidgetView({
  widget: 'weather',
  orientations: ['landscape'],
  zoneTypes: ['monitor-content--x'],
  props: { enableClock: true },
}, WeatherWidgetBottomWithCloclView);
