import React from 'react';
import { Row } from 'antd';

import styles from './TextWidgetPortraitView.module.less';
import templates from './TextWidgetTemplates.module.less';
import WidgetViewProvider from '../../../WidgetViewProvider';

const TextWidgetPortraitView = ({
  headline,
  subHeadline,
  template,
  text
}) => (
    <Row className={`${styles.root} ${templates[`hd-${template.background}-bg`]} ${templates[`${template.text}-text`]}`}>
      <div className={styles.container}>
        <Row className={styles.row}>
          <span className={styles.headLine}>
            {headline}
          </span>
        </Row>
        <Row className={styles.row}>
          <span className={styles.subHeadLine}>
            {subHeadline}
          </span>
        </Row>
        <Row>
          <span className={styles.text}>
            {text}
          </span>
        </Row>
      </div>
    </Row>
  );

WidgetViewProvider.registerWidgetView({
  widget: 'text',
  orientations: ['portrait'],
}, TextWidgetPortraitView);
