import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col  } from 'antd';

import styles from './WeatherWidgetView.module.less';
import WidgetViewProvider from '../../../WidgetViewProvider';
import WeatherIcon from '../atoms/WeatherIcon';

const WeatherWidgetView = ({
    cityName,
    time,
    dayPart,
    enableClock,
    temperature,
    group,
    description,
    ...props
}) => (
    <div {...props}>
        <Row className={styles.dateAndTimeRow}>
            <div className={styles.container}>
                <Row>
                    <span className={styles.date}>
                        {time.toFormat('LLLL dd')}.
                    </span>
                </Row>
                <Row>
                    <span className={styles.day}>
                        {time.toFormat('cccc')}
                    </span>
                </Row>
                {enableClock && (
                    <Row>
                        <span className={`${styles.time} ${time.toFormat('HH:mm').indexOf(1) === 0 ? styles.timeForOne : null} `}>
                            {time.toFormat('HH:mm')}
                        </span>
                    </Row>
                )}
            </div>
        </Row>
        <Row className={styles.weatherRow}>
            <div className={styles.container}>
                <Col span={24}>
                    <Row className={styles.temperatureContainer}>
                        <Col span={24}>
                            <span className={`${styles.temperature} ${temperature.toFixed(0).indexOf(1) === 0 ? styles.tempForOne : null}`}>
                                {temperature.toFixed(0)}°
                                <WeatherIcon className={styles.weatherImage} group={group} description={description} dayPart={dayPart} />
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <span className={styles.cityName}>
                            {cityName}
                        </span>
                    </Row>
                </Col>
            </div>
        </Row>
    </div>
);

WeatherWidgetView.propTypes = {
    cityName: PropTypes.string.isRequired,
    time: PropTypes.shape({}).isRequired, // should be moment propTypes
    enableClock: PropTypes.bool.isRequired,
    temperature: PropTypes.number.isRequired
};

WidgetViewProvider.registerWidgetView({
    widget: 'weather',
    default: true
}, WeatherWidgetView);