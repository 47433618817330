import React from 'react';
import PropTypes from 'prop-types';

import styles from './IframeWidgetView.module.less';
import { WidgetViewProvider } from '../../..';

const IframeWidgetView = ({
    url,
    ...props
}) => (
    <div className={styles.container}>
        <iframe
            width="100%"
            height="100%"
            src={url}
            title="IFrame widget"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            {...props}
        />
    </div>
);

IframeWidgetView.propTypes = {
    url: PropTypes.string.isRequired
};

WidgetViewProvider.registerWidgetView({
    widget: 'iframe',
    default: true,
  }, IframeWidgetView);