import React from 'react';
import PropTypes from 'prop-types';
import { Row  } from 'antd';

import styles from './ClockWidgetView.module.less';
import WidgetViewProvider from '../../../WidgetViewProvider';

const ClockWidgetView = ({
    time,
    ...props
}) => (
    <div className={styles.root} {...props}>
        <Row className={styles.dateRow}>
            <div className={styles.container}>
                <Row>
                    <span className={styles.date}>
                        {time.toFormat('LLLL dd')}.
                    </span>
                </Row>
                <Row>
                    <span className={styles.day}>
                        {time.toFormat('cccc')}
                    </span>
                </Row>
            </div>
        </Row>
        <Row className={styles.timeRow}>
            <div className={styles.container}>
                <Row justify="center">
                    <span className={styles.time}>
                        {time.toFormat('HH:mm')}
                    </span>
                </Row>
            </div>
        </Row>
    </div>
);

ClockWidgetView.propTypes = {
    time: PropTypes.shape({}).isRequired
};

WidgetViewProvider.registerWidgetView({
    widget: 'time',
    default: true
}, ClockWidgetView);