import { observable } from "mobx";

/**
 * @see https://openweathermap.org/weather-conditions
 */
export default class WeatherCondition {
    /**
     * @var {number}
     */
    @observable id;

    /**
     * @var {string}
     */
    @observable group;

    /**
     * @var {string}
     */
    @observable description;
}