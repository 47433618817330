import React from 'react';
import { Col, Row, Divider } from 'antd';
import Zone from '../../components/Zone/Zone';
import LayoutProvider from '../LayoutProvider';

const landscapeMainTopLeft = () => (
    <>
        <Row style={{height: '70vh'}}>
            <Col flex="70vw" style={{height: '70vh'}}>
                <Zone zone={0} />
            </Col>
            <div style={{ position: 'absolute', top: 0, left: '70vw', height: '70vh', zIndex: 99, width: '1px', backgroundColor: 'rgba(255, 255, 255, 0.2)'}}></div>
            <Col flex="30vw" style={{height: '70vh'}}>
                <Zone zone={1} />
            </Col>
        </Row>
        <Divider style={{ margin: 0, borderTop: '1px solid rgba(255, 255, 255, 0.2)', backgroundColor: '#2F3336' }} />
        <Row style={{height: '30vh'}}>
            <Col flex="100vw" style={{height: '30vh'}}>
                <Zone zone={2} fullscreenToggler/>
            </Col>
        </Row>
    </>
);

LayoutProvider.registerLayout("landscape-1", landscapeMainTopLeft);