import Api from '../../../../Api';

export const getWeather = async (city = 'Budapest', units = 'metric') => {
    try{
        const response = await Api.get(`/site/weather?string=${escape(`weather?q=${city}&units=${units}`)}`);
        const reponseData = await response.data;
        return reponseData.data;
    } catch (error) {
        console.error('Hiba történt...', error);
    }

    return null;
};