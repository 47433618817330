import React from 'react';
import { Row, Col  } from 'antd';

import styles from './Loader.module.less';
import loader from './loader.gif';

export default ({...props}) => (
    <div className={styles.root} {...props} >
        <Row align="middle" style={{height: '100%'}}>
            <Col span={24}>
                <img src={loader} className={styles.loader} alt="Loop loading..." title="Loading..."/>
                {/* Loading... */}
            </Col>
        </Row>
    </div>
);