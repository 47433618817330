import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { ReactComponent as IconExpandDarkSolid } from './assets/expand-dark-solid.svg';
import { ReactComponent as IconCompressDarkSolid } from './assets/compress-dark-solid.svg';
import { ReactComponent as IconExpandLightSolid } from './assets/expand-light-solid.svg';
import { ReactComponent as IconCompressLightSolid } from './assets/compress-light-solid.svg';
import styles from './FullScreenToggler.module.less';

@observer
class FullScreenToggler extends Component{
    static propTypes = {
        fullscreenToggler: PropTypes.bool.isRequired,
        light: PropTypes.bool.isRequired
    }

    static defaultProps = {
        fullscreenToggler: false,
        light: true
    }

    state = {
        isFullScreen: false
    }

    openFullscreen() {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
            document.documentElement.msRequestFullscreen();
        }

        this.setIsFullScreen(true);
    }
  
    closeFullscreen() {
        if (!this.isFullScreen()) {
            return;
        }
        try {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
            this.setIsFullScreen(false);
        } catch(e) {
            console.error(e);
        }
    }

    isFullScreen() {
        console.log(!window.screenTop && !window.screenY);
        return !window.screenTop && !window.screenY;
    }

    setIsFullScreen(state) {
        this.setState({
            isFullScreen: state
            // !window.screenTop && !window.screenY
        });
    }

    render() {
        return (
            <div className={styles.expandIcon}>
                {this.state.isFullScreen ? (
                    this.props.light 
                        ? <IconCompressLightSolid onClick={this.closeFullscreen.bind(this)} />
                        : <IconCompressDarkSolid onClick={this.closeFullscreen.bind(this)} />
                ) : (
                    this.props.light 
                        ? <IconExpandLightSolid onClick={this.openFullscreen.bind(this)} />
                        : <IconExpandDarkSolid onClick={this.openFullscreen.bind(this)} />
                )}
            </div>
        );
    }
}

export default FullScreenToggler;
