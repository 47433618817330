import { observer } from 'mobx-react';

class LayoutProvider {

    /**
     * @var {[name: string]: HTMLElement}
     */
    layouts = {}

    registerLayout(type, component) {
        let types = type;

        if (typeof type === 'string') {
            types = [type];
        }

        types.forEach(currentType => {
            this.layouts[currentType] = observer(component);
        });
    }

    /**
     * @var {string} type
     * @return {HTMLElement}
     */
    getLayout(type) {
        return this.layouts[type];
    }
}

export default new LayoutProvider();