import React from 'react';
import '../services/WeatherService';
import {Widget} from '../../Widget/index';
import { observer } from 'mobx-react';
import styles from './WeatherWidget.module.less';
import widgetRegister from '../../widgetRegister';
import WeatherWidgetProps from '../models/WeatherWidgetProps';
import './views';

@observer
@widgetRegister({name: 'weather', props: WeatherWidgetProps})
class WeatherWidget extends Widget {
    static propTypes = {
        ...Widget.PropTypes,
    };

    render() {
        return <div className={styles.root} attr={this.props.zone.zoneType}>
            {(!this.props.zone.loading && this.props.weather && this.props.weather.temperature !== undefined) && this.getView({
                cityName: this.props.weather.cityName,
                time: this.props.time,
                dayPart: this.props.dayPart,
                enableClock: this.props.enableClock,
                temperature: this.props.weather.temperature,
                group: this.props.weather.condition.group,
                description: this.props.weather.condition.description,
                className: styles.root,
            })}
        </div>;
    }
}


export default WeatherWidget;