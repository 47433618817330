import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import styles from './ClockWidgetSubView.module.less';
import WidgetViewProvider from '../../../WidgetViewProvider';

const ClockWidgetSubView = ({
  time,
  ...props
}) => (
    <div className={styles.root} {...props}>
      <div className={styles.mainContainer}>
        <div className={styles.verticalLine}></div>
        <Row style={{ height: '100%' }} align="middle">
          <Col flex="70vw" style={{height: "100%"}}>
            <Row style={{ height: '100%' }} align="middle">
              <Col span={24}>
                <Row style={{ height: '100%' }} align="middle" justify="center">
                  <span className={styles.date}>
                    {time.toFormat('LLLL dd')}.
                  </span>
                  <span className={styles.day}>
                    &nbsp;{time.toFormat('cccc')}
                  </span>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col flex="30vw" style={{ height: "100%" }}>
            <Row style={{ height: '100%' }} align="middle" justify="center">
              <span className={styles.time}>
                {time.toFormat('HH:mm')}
              </span>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );

ClockWidgetSubView.propTypes = {
  time: PropTypes.shape({}).isRequired
};

WidgetViewProvider.registerWidgetView({
  widget: 'time',
  orientations: ['portrait'],
  zoneTypes: ['monitor-content--widget'],
}, ClockWidgetSubView);