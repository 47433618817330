import React, {useRef} from "react";
import PropTypes from "prop-types";
import ReactPlayer from 'react-player'

import styles from "./VideoWidgetView.module.less";
import WidgetViewProvider from "../../../WidgetViewProvider";

const VideoWidgetView = React.memo(({ url, muted, ...props }) => {
  const player = useRef(null);
  
  if (player.current) {
    player.current.seekTo(0);
  }

  return  (
    <div className={styles.container} {...props}>
      <ReactPlayer
        ref={player}
        preload="auto"
        width="100%"
        height="100%"
        muted
        loop={false}
        playing
        light={false}
        aspectRatio="16:9"
        controls={false}
        url={url}
        stopOnUnmount={true}
      />
    </div>
  );
});

VideoWidgetView.propTypes = {
  url: PropTypes.string.isRequired,
};

WidgetViewProvider.registerWidgetView(
  {
    widget: "video",
    default: true,
}, VideoWidgetView);
